/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    blockquote: "blockquote",
    p: "p",
    em: "em",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Para cuidar la privacidad de las personas en el fondo se necesitan sólo pequeños detalles: definir muy bien quién y bajo qué condiciones tendrá acceso a los datos, y minimizar el viaje de estos por la red de redes usando, si es posible, una solución ", React.createElement(_components.em, null, "in-house"), " hecha con software libre del que nos podamos fiar y reduciendo la posibilidad de acceso imprevisto a la agregación de toda nuestra actividad interna. Como decíamos, cuidar a las personas que forman parte del proyecto no es baladí: son el origen de todo lo bueno que logremos."), "\n", React.createElement(_components.p, null, "Jose Alcántara, en ", React.createElement(_components.a, {
    href: "http://www.cartograf.net/articulo/conceptualizando-empresa-cuantificada-yo-cuantificado-empresa",
    title: "Conceptualizando la empresa cuantificada, sinergias del yo cuantificado en entornos profesionales"
  }, "Cartograf.")), "\n"), "\n", React.createElement(_components.p, null, "Exactamente a esto es a lo que me refería en mi ultimo post, ", React.createElement(_components.a, {
    href: "https://litox9.wordpress.com/2012/11/18/red-de-hogar/"
  }, "Red de Hogar"), ", aunque lo desarrollé poco, la idea era exactamente esta, crear una red interna de donde no salgan los datos que no quieras exponer. El paralelismo entre hogar y empresa es muy claro en este caso y por eso mismo utilizo esta palabra y no otras como vivienda, casa, etc. El hogar es algo más que eso, la zona de confort de una comunidad, pero esto lo dejaré para otra entrada."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
